import Vue from 'vue';

class HubPlugin {
  constructor({ context, $axios, route }) {
    this.$axios = $axios;
    this.context = context;
    this.route = route;
    this.enrolledCourses = [];
    this.allCourses = [];
    this.initializedRoute = null;

    this.setInitializedRoute();
    this.fetch();
  }

  setInitializedRoute() {
    this.initializedRoute = this.route.name;
  }

  async fetch() {
    const response = await this.$axios.get('/api/v1/caap/hub/courses');
    const { enrolled_courses, all_courses } = response.data;

    Vue.set(this, 'enrolledCourses', enrolled_courses);
    Vue.set(this, 'allCourses', all_courses);
  }

  async resetDefaultCourse() {
    await this.$axios.get('/api/v1/caap/user/enrollments/reset');
    for (const courseType of this.enrolledCourses) {
      for (const course of courseType.courses) {
        if (course.has_default_enrollment) {
          Vue.set(course, 'has_default_enrollment', false);
          return;
        }
      }
    }
  }

  setDefaultCourse(courseId) {
    let modificationData;

    this.enrolledCourses.forEach((courseType, courseTypeIndex) => {
      courseType.courses.forEach((course, courseIndex) => {
        if (course.id === courseId) {
          Vue.set(course, 'has_default_enrollment', true);
          modificationData = { courseType, course, courseTypeIndex, courseIndex };
        } else if (course.has_default_enrollment) {
          Vue.set(course, 'has_default_enrollment', false);
        }
      });
    });

    // Sort the default course
    if (Object.keys(modificationData).length) {
      const { courseType, course, courseTypeIndex, courseIndex } = modificationData;
      if (courseIndex > 0) {
        courseType.courses.splice(courseIndex, 1);
        courseType.courses.unshift(course);
      }

      // Move the default courseType to the top of enrolledCourses
      if (courseTypeIndex > 0) {
        this.enrolledCourses.splice(courseTypeIndex, 1);
        this.enrolledCourses.unshift(courseType);
      }
    }
  }

  get defaultCourse() {
    for (const courseType of this.enrolledCourses) {
      const defaultCourse = courseType?.courses?.find((course) => course.has_default_enrollment);
      if (defaultCourse) {
        return defaultCourse;
      }
    }

    return null;
  }

  get defaultField() {
    for (const courseType of this.enrolledCourses) {
      const defaultCourse = courseType?.courses?.find((course) => course.has_default_enrollment);
      if (defaultCourse) {
        return courseType.field;
      }
    }

    return null;
  }

  get isInitializedFromHomePage() {
    return this.initializedRoute === 'index';
  }
}

export default ({ app }, inject) => {
  inject('hub', new HubPlugin({ context: app, $axios: app.$axios, route: app.context.route }));
};
